.card__container {
  flex: 1;
  border-radius: 12px;
  height: 615px;
}

.card__container--header {
  border-radius: 12px 12px 0px 0px !important;
  padding: 1rem !important;
}

.card__header--text {
  color: #fff;
  margin: 0px;
}

.card__content {
  /* height: 540px; */
  padding: 1rem;
}

.card__content--container {
  padding: 15px;
}