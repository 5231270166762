:root {
  --textColorDark: #ffffff;
  --textColor: #000000;
  --faceBook: #3b5998;
  --linkedin: #0e76a8;
  --github: #333;
  --gitlab: #fca326;
  --google: #ea4335;
  --twitter: #1da1f2;
  --medium: #000;
  --stackoverflow: #f48024;
  --instagram: #c13584;
  --kaggle: #20beff;
}

.social-media-div {
  font-size: 2em;
}

.icon-button {
  margin-bottom: 10px;
}

.icon-button i {
  color: var(--textColorDark);
  border-radius: 2.6rem;
  cursor: pointer;
  display: inline-block;
  font-size: 1.3rem;
  height: 2.6rem;
  line-height: 2.6rem;
  margin: 0 5px;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 2.6rem;
  margin-bottom: 10px;
  transition: 0.2s ease-in;
}

.facebook i {
  background-color: var(--faceBook);
}

.linkedin i {
  background-color: var(--linkedin);
}

.github i {
  background-color: var(--github);
}

.gitlab i {
  background-color: var(--gitlab);
}

.google i {
  background-color: var(--google);
}

.twitter i {
  background-color: var(--twitter);
}

.medium i {
  background-color: var(--medium);
}

.stack-overflow i {
  background-color: var(--stackoverflow);
}

.kaggle i {
  background-color: var(--kaggle);
}

.instagram i {
  background-color: var(--instagram);
}

/* Hover Transition */
.twitter i:hover,
.google i:hover,
.gitlab i:hover,
.github i:hover,
.linkedin i:hover,
.facebook i:hover,
.instagram i:hover,
.stack-overflow i:hover,
.kaggle i:hover,
.medium i:hover {
  background-color: var(--textColor);
}

/* Media Query */
@media (max-width: 768px) {
  .social-media-div {
    text-align: center;
  }
}
