:root {
  --darkBackground: #171c28;
  --textColorDark: #ffffff;
  --buttonColor: #55198b;
  --lightBackground1: #fff;
  --subTitle: #868e96;
  --textColor: #000000;
  --headerHoverBG: #f4f4f4;
  --iconBackground: #333;
}

@font-face {
	font-family: Augestina;
	src: url("../../assets/fonts/Agustina.otf");
}


/*React-headroom*/
.headroom-wrapper {
  /* max-height: 100px; */
  max-height: 70px;
  background-color: var(--darkBackground) !important;
}

/* header */

.dark-menu {
  background-color: var(--darkBackground) !important;
  color: var(--textColorDark);
}

.dark-menu li a {
  color: var(--textColorDark) !important;
}

.dark-menu li a:hover {
  background-color: var(--subTitle) !important;
}

.header {
  width: 100%;
  padding: 5px 20px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 16px;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

.grey-color {
  /* color: var(--subTitle); */
  color: var(--lightBackground1);
}

.header li a {
  display: block;
  padding: 15px 20px;
  color: var(--textColor);
  text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
  background-color: var(--headerHoverBG);
  /* background-color: var(--subTitle); */
}

.header .logo {
  /* display: block; */
  /* float: left; */
  /* flex: none; */
  /* margin-top: 10px; */
  font-size: 1.5em;
  text-decoration: none;
  color: #fff;
  padding: 2px;
}

.header .logo-name {
  font-family: Augestina;
  font-weight: bold;
  font-variant-ligatures: no-common-ligatures;
  -webkit-font-variant-ligatures: no-common-ligatures;
  padding: 0 10px;
}

/* menu */

.header .menu {
  clear: both;
  max-height: none;
  transition: max-height 0.2s ease-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  /* display: inline-block; */
  display: none;
  /* position: relative;
  float: right; */
  padding: 28px 20px;
  user-select: none;
}

.header .menu-icon .navicon {
  /* background: var(--iconBackground); */
  background: var(--lightBackground1);
  display: block;
  height: 2px;
  position: relative;
  /* transition: var(--iconBackground) 0.2s ease-out; */
  transition: var(--lightBackground1) 0.2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon-dark {
  background: var(--textColorDark);
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  /* background: var(--iconBackground); */
  background: var(--lightBackground1);
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon-dark:before,
.header .menu-icon .navicon-dark:after {
  background: var(--textColorDark) !important;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 486px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent !important;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}


/* 48em = 768px */
@media (max-width: 768.99px) {
 .header {
   display: block;
   /* font-size: 12px; */
   font-size: 14px;
   min-height: 50px;
   padding: 5px 10px;
 }

 /* .menu {
   display: none;
 } */

/* .header .menu-icon {
  padding: 7px 5px;
  position: relative;
  float: right;
} */

.header .menu-icon {
  display: block;
  /* padding: 14px 10px; */
  padding: 1.2rem 0.65rem;
  position: relative;
  float: right;
}

.header .menu {
  max-height: 0;
}

}

@media (min-width: 48em) {
  .header li {
    float: left;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  /* .header .menu {
    max-height: none;
  } */

  /* .header .menu-icon {
    display: none;
  } */
}