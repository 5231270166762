.education-card {
	display: flex;
	flex-direction: row;
}

.education-card:hover + .education-card-border {
	border-color: #a9a7f9;
	width: 100%;
	-webkit-transition: width 0.6s ease-in-out;
	-moz-transition: width 0.6s ease-in-out;
	-o-transition: width 0.6s ease-in-out;
	transition: width 0.6s ease-in-out;
}

.education-card-border {
	height: 30px;
	width: 80%;
	border-top: 2px solid rgba(211, 211, 211, 0.397);
	margin-bottom: 20px;
	-webkit-transition: width 0.6s ease-in-out;
	-moz-transition: width 0.6s ease-in-out;
	-o-transition: width 0.6s ease-in-out;
	transition: width 0.6s ease-in-out;
}

.education-head {
	display: flex;
	flex-direction: row;
}

.education-text-school {
	margin: 0px;
	font-size: 28px;
	font-weight: 700;
	white-space: nowrap;
	/* height: 27px; */
	color: #fff;
}

.education-img {
	object-fit: cover;
	margin-right: 5rem;
	width: 15rem;
}


.education-card-right {
	max-width: 70%;
}

.education-card-left {
	align-self: center;
	padding: 1rem;
}

.education-text-subHeader {
	color: #fff;
	font-weight: 700;
	font-size: 19px;
	margin: 0px;
	padding-top: 0.8rem;
	line-height: normal;
}

.education-text-duration {
	color: #fff;
	font-size: 19px;
	margin: 0px;
	padding-top: 0.5rem;
}

.education-text-desc {
	color: #fff;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.education-text-bullets > ul {
	margin-top: 0.5rem;
}

.subTitle {
	color: #fff;
	font-weight: 300;
}

/* Media Query */
@media (max-width: 1380px) {
	.education-card {
		padding-bottom: 10px;
	}

	.education-card-right {
		max-width: 90%;
	}

	.education-card-border {
		width: 90%;
	}

	.education-text-subHeader {
		padding-top: 0.5rem;
		font-size: 16px;
	}

	.education-text-school {
		font-size: 20px;
	}

	.education-text-duration {
		font-size: 16px;
	}

	.education-roundedimg {
		width: 4rem;
		height: 4rem;
		margin-bottom: 0.5rem;
		margin-right: 0px;
	}
}

@media (max-width: 768px) {
	.education-card {
		text-align: center;
		flex-direction: column;
		padding-bottom: 10px;
	}

	.education-card-right {
		max-width: 100%;
	}

	.education-card-border {
		width: 100%;
	}

	.education-img {
		width: 12rem;
		margin: 10px 0px;
	}

	.education-text-bullets > ul {
		max-width: 80%;
		margin: auto;
	}
}